<template>
  <v-container class="mt-4 px-5" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            <span class="text-subtitle-1">Actas administrativas</span>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="initialize( )"
              small
              rounded
            >
              Actualizar
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row justify="end">
              <v-col cols="12" md="6" lg="4">
                <v-text-field
                  name="name"
                  label="Buscar"
                  id="id"
                  filled
                  dense
                  hide-details
                  append-icon="mdi-magnify"
                  v-model="search"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="actas"
                  class="elevation-0"
                  :search="search"
                  :page.sync="page"
                  @page-count="pageCount = $event"
                  :mobile-breakpoint="100"
                  hide-default-footer
                  dense
                >

                  <template v-slot:item.estatus="{ item }">
                    <div v-if="!item.estatus">
                      <v-radio-group
                        v-model="item.estatus"
                        row
                        hide-details
                        class="ma-0"
                      >
                        <v-radio
                          label="Aceptar"
                          :value="1"
                          @click="save( item )"
                        ></v-radio>
                        <v-radio
                          label="Rechazar"
                          :value="2"
                          @click="save( item )"
                        ></v-radio>
                      </v-radio-group>
                    </div>
                    <v-chip small color="green" dark v-if="item.estatus == 1">Aceptada</v-chip>
                    <v-chip small color="black" dark v-if="item.estatus == 2">Rechazada</v-chip>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-btn 
                      color="primary" 
                      x-small
                      @click="editItem(item)"
                      class="mr-2"
                    >
                      <v-icon small>mdi-magnify</v-icon>
                    </v-btn>
                  </template>

                  <template v-slot:no-data>
                    <v-btn
                      color="primary"
                      @click="initialize"
                      small
                    >
                      Actualizar
                    </v-btn>
                  </template>

                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-pagination
              circle
              v-model="page"
              :length="pageCount"
              :total-visible="7"
            ></v-pagination>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialogo de editar o agregar-->
    <v-dialog
      v-model="dialog"
      max-width="600px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
            <strong>ID: {{ editedItem.idactas_admin }}</strong>
          </span>
        </v-card-title>

        <v-card-text class="black--text">
          <div>
            Sanción
            <br/>
            {{ editedItem.asignado }}
          </div>

          <br/>

          <div>
            Sanción
            <br/>
            {{ editedItem.falta }}
          </div>

          <br/>

          <div>
            Sanción
            <br/>
            {{ editedItem.sancion }}
          </div>
          <br/>
          

          <v-textarea
            v-model="editedItem.motivo"
            readonly
            filled
            rows="3"
            label="Agrega una descripción"
          >
          </v-textarea>

          <div v-if="!editedItem.estatus">
            <v-radio-group
              v-model="editedItem.estatus"
              row
              hide-details
              class="ma-0"
            >
              <v-radio
                label="Aceptar"
                :value="1"
              ></v-radio>
              <v-radio
                label="Rechazar"
                :value="2"
              ></v-radio>
            </v-radio-group>
          </div>
          <v-chip small color="green" dark v-if="editedItem.estatus == 1">Aceptada</v-chip>
          <v-chip small color="black" dark v-if="editedItem.estatus == 2">Rechazada</v-chip>

        </v-card-text>

        <v-card-actions>
          <v-btn
            color="red"
            rounded
            dark
            small
            @click="close"
          >
            Cerrar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            rounded
            dark
            small
            @click="save( editedItem )"
          >
            Actualizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';
  import validarErrores        from '@/mixins/validarErrores'


  export default {
    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      
      editedIndex: -1,

      editedItem: {
        idactas_admin:0,
        id_asigno:0,
        id_usuario:0,
        motivo:'',
        id_sancion:0,
        id_faltas:0,
      },

      defaultItem: {
        idactas_admin:0,
        id_asigno:0,
        id_usuario:0,
        motivo:'',
        id_sancion:0,
        id_faltas:0,
      },

      // Filtros: 
      incio:null,
      fin:null,

      // tabla
      search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      actas: [],
      headers: [
        { text: 'ID'               , value: 'idactas_admin'  },
        { text: 'Empleado'         , value: 'asignado'       },
        { text: 'Falta'            , value: 'falta'          },
        { text: 'Sanción'          , value: 'sancion'        },
        { text: 'Motivo'           , value: 'motivo'         },
        { text: 'Estatus'          , value: 'estatus'        },
        { text: 'fecha_ creación'  , value: 'fecha_creacion' },
        { text: 'Actions'          , value: 'actions', sortable: false },
      ],

      faltas:[],
      sanciones:[],
      usuarios:[]
    }),

    computed: {
      ...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar actas admin.' : 'Editar actas admin.'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

    },

    async created () {
      await this.initialize()      
    },

    methods: {
      initialize () {
        this.cargar = true
        this.actas = []
        return this.$http.get('actas.usuario/' + this.getdatosUsuario.iderp ).then(response=>{
          this.actas = response.data
          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {
        this.editedIndex = this.actas.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.actas.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.cargar = true
        // Lo mandapos por el EP
        this.editedItem['deleted'] = 1
        this.$http.put('actas.update/' + this.editedItem.idactas_admin, this.editedItem).then(response=>{
          this.cargar = true
          this.closeDelete()
          this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save ( item ) {
        // Cargamos al usuario que hizo el cambio
        this.cargar = true
        // Lo mandapos por el EP
        this.$http.put('actas.update/' + item.idactas_admin, item).then(response=>{
          this.validarSuccess( response.data.message )
          this.initialize()
          this.close()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },
    },
  }
</script>


